import React, {useState} from "react"
import PasswordChecklist from "react-password-checklist"

//ref: https://www.npmjs.com/package/react-password-checklist
const PasswordValTest = () => {
	const [password, setPassword] = useState("");
	const [passwordAgain, setPasswordAgain] = useState("");
    const rules = ["minLength","match"]; // ,"specialChar","number","capital"
    const messages = {
        minLength: "비밀 번호의 최소 길이는 8 입니다.",
        //specialChar: "La contraseña tiene caracteres especiales.",
        //number: "La contraseña tiene un número.",
        //capital: "La contraseña tiene una letra mayúscula.",
        match: "새 비밀번호와 확인 번호가 일치하지 않습니다",
    };

	return (
		<form>
			<label>Password:</label>
			<input type="password" onChange={e => setPassword(e.target.value)} />
			<label>Password Again:</label>
			<input type="password" onChange={e => setPasswordAgain(e.target.value)} />

			<PasswordChecklist
				rules={rules}
				minLength={8}
				value={password}
				valueAgain={passwordAgain}
				// onChange={(isValid) => {}}
                messages={messages}
			/>
		</form>
	);
}

export default PasswordValTest;