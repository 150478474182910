import React, { useState, useEffect, useCallback } from 'react';

let logoutTimer;

const AuthContext = React.createContext({
    email: '',
    token: '',
    role: '',
    idSaved: false,
    isLoggedIn: false,
    login: () => { },
    logout: () => { },
});

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};

const retrieveStoredToken = () => {
    const storedEmail = localStorage.getItem('email');
    const storedRole = localStorage.getItem('role');
    const storedToken = localStorage.getItem('token');
    const storedExpirationDate = localStorage.getItem('expirationTime');
    const storedIdSaved = localStorage.getItem('idSaved');

    const remainingTime = calculateRemainingTime(storedExpirationDate);

    if (remainingTime <= 3600) {
        if(storedIdSaved === 'false')
        {
            localStorage.removeItem('email');
        }
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        return null;
    }

    return {
        email: storedEmail,
        role: storedRole,
        token: storedToken,
        duration: remainingTime,
        IdSaved : storedIdSaved
    };
};

export const AuthContextProvider = (props) => {
    const tokenData = retrieveStoredToken();

    let initialToken;
    let initialEmail;
    let initialRole;
    let initialIdSaved;

    if (tokenData) {
        initialToken = tokenData.token;
        initialEmail = tokenData.email;
        initialRole = tokenData.role;
        initialIdSaved = tokenData.IdSaved;
    }
    
    const [token, setToken] = useState(initialToken);
    const [email, setEmail] = useState(initialEmail);
    const [role, setRole] = useState(initialRole);
    const userIsLoggedIn = !!token;
    const [idSaved, setidSaved] = useState(initialIdSaved);

    const logoutHandler = useCallback(() => {
        setToken(null);

        if(idSaved === 'false'){
            localStorage.removeItem('email');
        }
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');

        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }
    }, [idSaved]);

    const loginHandler = (email, role, token, expirationTime,idSaved) => {
        setToken(token);
        setEmail(email);
        setRole(role);
        setidSaved(idSaved);

        localStorage.setItem('email', email);
        localStorage.setItem('role', role);
        localStorage.setItem('token', token);
        localStorage.setItem('idSaved', idSaved);
        localStorage.setItem('expirationTime', expirationTime);

        const remainingTime = calculateRemainingTime(expirationTime);

        logoutTimer = setTimeout(logoutHandler, remainingTime);
    };

    useEffect(() => {
        if (tokenData) {
            // console.log(tokenData.duration);
            logoutTimer = setTimeout(logoutHandler, tokenData.duration);
        }
    }, [tokenData, logoutHandler]);

    const contextValue = {
        email:email,
        role:role,
        token: token,
        idSaved: idSaved,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;