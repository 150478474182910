export function truncate(str, n) {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
};

export const isNotEmpty = (value) => value.trim() !== '';

// ref: https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
export const isValidEmail = (email) => {
    if (email === '') return false;

    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

// Create our number formatter.
export const formatter = new Intl.NumberFormat('ko-KR', {
    style: 'currency',
    currency: 'KRW',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

// common constant
export const USER_TYPE = {
    User: 'User',
    Headhunter: 'Headhunter'
}