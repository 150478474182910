import React, { useMemo } from 'react';
import MaterialReactTable from 'material-react-table';

const data = [
  {
    name: 'John', // key "name" matches `accessorKey` in ColumnDef down below
    age: 30, // key "age" matches `accessorKey` in ColumnDef down below
  },
  {
    name: 'Sara',
    age: 25,
  },
];

const MaterialReactTableTest = () => {

  //simple column definitions pointing to flat data
  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //simple recommended way to define a column
        header: "Name",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong> //optional custom cell render
      },
      {
        accessorFn: (row) => row.age, //alternate way
        id: "age", //id required if you use accessorFn instead of accessorKey
        header: "Age",
        Header: <i style={{ color: "red" }}>Age</i> //optional custom markup
      }
    ],
    [],
  );

  return (
    <React.Fragment>
      <MaterialReactTable
        columns={columns}
        data={data}
        //enableRowSelection //enable some features
        //enableColumnOrdering
        //enableGlobalFilter={false} //turn off a feature
      />
    </React.Fragment>
  );
}

export default MaterialReactTableTest;
