import './assets/css/global.css';
import React, { Suspense } from 'react';

//import Header from 'components/Layout/Header';
//import Footer from 'components/Layout/Footer';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useContext } from 'react'; // authorization
import AuthContext from './components/Store/AuthContext';
//import LoadingSpinner from './components/UI/LoadingSpinner';

import MaterialReactTableTest from 'components/Test/MaterialReactTableTest';
import { USER_TYPE } from 'hooks/utility';
import PasswordValTest from 'components/Test/PasswordValTest';

// import HeadHunterMain from 'components/HeadHunter/HeadHunterMain';
// import HttpRequestTest from 'components/Test/HttpRequestTest';
// import Login from 'components/Auth/Login';
// import Register from 'components/Auth/Register';
// import JobSeekerMain from 'components/JobSeeker/JobSeekerMain';
// import ExperienceMain from 'components/JobSeeker/Experience/ExperienceMain';
// import BasicInfoMain from 'components/JobSeeker/BasicInfo/BasicInfoMain';
// import ResumeMain from 'components/JobSeeker/ResumeCoverLetter/ResumeMain';
// import CoverLetterMain from 'components/JobSeeker/ResumeCoverLetter/CoverLetterMain';
// import PasswordChange from 'components/Auth/PasswordChange';
// import Deregister from 'components/Auth/Deregister';
// import OnetoOneServiceInfo from 'components/Consulting/OnetoOneServiceInfo';
// import OnetoOneServiceApply from 'components/Consulting/OnetoOneServiceApply';
// import CreditResumeCoverLetter from 'components/Consulting/CreditResumeCoverLetter';
// import CreditCoding from 'components/Consulting/CreditCoding';
// import CreditInterview from 'components/Consulting/CreditInterview';
// import CreditQA from 'components/Consulting/CreditQA';
// import AppliedCompanyMain from 'components/JobSeeker/Applied/AppliedCompanyMain';
// import BookmarkedCompanyMain from 'components/JobSeeker/Applied/BookmarkedCompanyMain';
// import JobSeekerPayment from 'components/Payment/JobSeekerPayment';
// import MessageMain from 'components/Chat/MessageMain';
// import JobSeekerSchedule from 'components/Schedule/JobSeekerSchedule';
// import PostTemplate from 'components/Post/JobResource/PostTemplate';
//import BootstrapReactTest from 'components/Test/BootstrapReactTest'; // TODO: TBD
//import MUIDataGrid from 'components/Test/MUIDataGrid'; // TODO: TBD

// NOTE: lazy load
// import MainContent from 'components/Layout/MainContent';
// import ConsultingMain from 'components/Consulting/ConsultingMain';
// import JobProcessMain from 'components/JobProcess/JobProcessMain';
// import PeopleSearchMain from 'components/PeopleSearch/PeopleSearchMain';
// import JobResourceMain from 'components/JobResource/JobResourceMain';
// import SalTechMain from 'components/SalaryTechAnalytics/SalTechMain';
// import JobList from 'components/Job/JobList';
// import JobSeekerList from 'components/JobSeeker/JobSeekerList';
// import CompanyList from 'components/Company/CompanyList';
// import ConsultingMemberList from 'components/Consulting/ConsultingMemberList';
// import JobArticleList from 'components/Article/JobArticleList';
// import JobArticle from 'components/Article/JobArticle';
// import Faq from 'components/Article/Faq';
// import ConsultingArticle from 'components/Article/ConsultingArticle';
// import JobItemDetail from 'components/Job/JobItemDetail';

const Header = React.lazy(() => import('components/Layout/Header'));
const Footer = React.lazy(() => import('components/Layout/Footer'));
const LoadingSpinner = React.lazy(() => import('./components/UI/LoadingSpinner'));

const MainContent = React.lazy(() => import('components/Layout/MainContent'));
const ConsultingMain = React.lazy(() => import('components/Consulting/ConsultingMain'));
const JobProcessMain = React.lazy(() => import('components/Post/JobProcess/JobProcessMain'));
const PeopleSearchMain = React.lazy(() => import('components/PeopleSearch/PeopleSearchMain'));
const JobResourceMain = React.lazy(() => import('components/Post/JobResource/JobResourceMain'));
const SalTechMain = React.lazy(() => import('components/Post/SalaryTechAnalytics/SalTechMain'));
const JobList = React.lazy(() => import('components/Job/JobList'));
const JobSeekerList = React.lazy(() => import('components/JobSeeker/JobSeekerList'));
const CompanyList = React.lazy(() => import('components/Company/CompanyList'));
const ConsultingMemberList = React.lazy(() => import('components/Consulting/ConsultingMemberList'));
const JobArticleList = React.lazy(() => import('components/Article/JobArticleList'));
const JobArticle = React.lazy(() => import('components/Article/JobArticle'));
const Faq = React.lazy(() => import('components/Article/Faq'));
const ConsultingArticle = React.lazy(() => import('components/Article/ConsultingArticle'));
const JobItemDetail = React.lazy(() => import('components/Job/JobItemDetail'));
const ResumeBuilder = React.lazy(() => import('components/External/ResumeBuilder'));
const HttpRequestTest = React.lazy(() => import('components/Test/HttpRequestTest'));
const Login = React.lazy(() => import('components/Auth/Login'));
const Register = React.lazy(() => import('components/Auth/Register'));
const JobSeekerMain = React.lazy(() => import('components/JobSeeker/JobSeekerMain'));
const ExperienceMain = React.lazy(() => import('components/JobSeeker/Experience/ExperienceMain'));
const BasicInfoMain = React.lazy(() => import('components/JobSeeker/BasicInfo/BasicInfoMain'));
const ResumeMain = React.lazy(() => import('components/JobSeeker/ResumeCoverLetter/ResumeMain'));
const CoverLetterMain = React.lazy(() => import('components/JobSeeker/ResumeCoverLetter/CoverLetterMain'));
const PasswordChange = React.lazy(() => import('components/Auth/PasswordChange'));
const Deregister = React.lazy(() => import('components/Auth/Deregister'));
const OnetoOneServiceInfo = React.lazy(() => import('components/Consulting/OnetoOneServiceInfo'));
const OnetoOneServiceInfoHeadHunter = React.lazy(() => import('components/ConsultingHeadHunter/OnetoOneServiceInfo'));
const OnetoOneServiceApply = React.lazy(() => import('components/Consulting/OnetoOneServiceApply'));
const OnetoOneServiceApplyHeadHunter = React.lazy(() => import('components/ConsultingHeadHunter/OnetoOneServiceApply'));
const CreditResumeCoverLetter = React.lazy(() => import('components/Consulting/CreditResumeCoverLetter'));
const CreditCoding = React.lazy(() => import('components/Consulting/CreditCoding'));
const CreditInterview = React.lazy(() => import('components/Consulting/CreditInterview'));
const CreditQA = React.lazy(() => import('components/Consulting/CreditQA'));
const AppliedCompanyMain = React.lazy(() => import('components/JobSeeker/Applied/AppliedCompanyMain'));
const BookmarkedCompanyMain = React.lazy(() => import('components/Bookmark/BookmarkedCompanyMain'));
const BookmarkedPeople = React.lazy(() => import('components/Bookmark/BookmarkedPeople'));
const PaymentHistory = React.lazy(() => import('components/Payment/PaymentHistory'));
const MessageMain = React.lazy(() => import('components/Chat/MessageMain'));
const JobSeekerSchedule = React.lazy(() => import('components/Schedule/JobSeekerSchedule'));
const PostTemplate = React.lazy(() => import('components/Post/JobResource/PostTemplate'));
const PeopleItemDetail = React.lazy(() => import('components/PeopleSearch/PeopleItemDetail'));
const HeadHunterMain = React.lazy(() => import('components/HeadHunter/HeadHunterMain'));
const HeadHunterBasicInfoMain = React.lazy(() => import('components/HeadHunter/BasicInfo/HeadHunterBasicInfoMain'));
const HeadHunterJobRegister = React.lazy(() => import('components/HeadHunter/Job/JobRegister'));
const HeadHunterJobList = React.lazy(() => import('components/HeadHunter/Job/JobList'));

function App() {
  const authCtx = useContext(AuthContext);
  
  return (
    <React.Fragment>
      <Suspense fallback={
        <div className='centered'>
          <LoadingSpinner />
        </div>
      }>
        <Header></Header>
        <Routes>
          <Route path="/" element={<MainContent />} />
          {!authCtx.isLoggedIn && (
            <Route path="login" element={<Login />} />
          )}
          {!authCtx.isLoggedIn && (
            <Route path="register" element={<Register />} />
          )}
          {/* {authCtx.isLoggedIn && (
            <Route path="profile" element={<MemberProfile />} />
          )} */}
          {/* instead of redirect
              ref: https://stackoverflow.com/questions/69868956/how-can-i-redirect-in-react-router-v6
           */}
          <Route path="consulting" element={<ConsultingMain />} />
          <Route path="jobprocess" element={<JobProcessMain />}>
            <Route path=":slug" element={<PostTemplate />} />
          </Route>
          <Route path="peoplesearch" element={<PeopleSearchMain />}></Route>
          <Route path="peoplesearch/:id" element={<PeopleItemDetail />}></Route>
          <Route path="jobresource" element={<JobResourceMain />} >
            <Route path=":slug" element={<PostTemplate />} />
          </Route>
          <Route path="techanalysis" element={<SalTechMain />} >
            <Route path=":slug" element={<PostTemplate />} />
          </Route>
          <Route path="joblist" element={<JobList />} />
          <Route path="joblist/:id" element={<JobItemDetail />} />
          <Route path="jobseeker" element={<JobSeekerList />} />
          <Route path="jobarticlelist" element={<JobArticleList />}>
            {/* Index routes are the default child route for a parent route. */}
            {/* <Route
                index
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>Select an article list........</p>
                  </main>
                }
              /> */}
            <Route path=":id" element={<JobArticle />} />
          </Route>
          <Route path="company" element={<CompanyList />} />
          <Route path="consultingmember" element={<ConsultingMemberList />} />
          <Route path="faq" element={<Faq />} />
          <Route path="rb" element={<ResumeBuilder />} />
          <Route path="consultingarticle" element={<ConsultingArticle />} />
          {/* ref: https://stackabuse.com/redirects-in-react-router/ */}
          <Route path="/myinfo" element={<Navigate to="/jobseekermain/basicinfo"></Navigate>} />
          <Route path="jobseekermain" element={
            authCtx.isLoggedIn ? authCtx.role === USER_TYPE.User ? <JobSeekerMain />: <Navigate to="/headhuntermain/basicinfo" /> 
                               : <Navigate to="/login" replace></Navigate>
          }>
            <Route path="basicinfo" element={<BasicInfoMain />} />
            <Route path="experiencemain" element={<ExperienceMain />} />
            <Route path="resume" element={<ResumeMain />} />
            <Route path="coverletter" element={<CoverLetterMain />} />
            <Route path="password" element={<PasswordChange />} />
            <Route path="exit" element={<Deregister />} />
            <Route path="serviceinfo" element={<OnetoOneServiceInfo />} />
            <Route path="serviceapply" element={<OnetoOneServiceApply />} />
            <Route path="creditresume" element={<CreditResumeCoverLetter />}>
              <Route path=":active_tab" element={<CreditResumeCoverLetter />} />
            </Route>
            <Route path="creditcoding" element={<CreditCoding />} />
            <Route path="creditinterview" element={<CreditInterview />} />
            <Route path="creditqa" element={<CreditQA />} />
            <Route path="appliedcompanymain" element={<AppliedCompanyMain />} />
            <Route path="bookmarkcompanymain" element={<BookmarkedCompanyMain />} />
            <Route path="payment" element={<PaymentHistory />} />
            <Route path="message" element={<MessageMain />} />
            <Route path="schedule" element={<JobSeekerSchedule />} />
          </Route>

          {/* ref: https://stackabuse.com/redirects-in-react-router/ */}
          <Route path="headhuntermain" element={
            authCtx.isLoggedIn ? authCtx.role === USER_TYPE.Headhunter ? <HeadHunterMain />: <Navigate to="/jobseekermain/basicinfo" />  
            : <Navigate to="/login" replace></Navigate>
          }>
            <Route path="basicinfo" element={<HeadHunterBasicInfoMain />} />
            <Route path="password" element={<PasswordChange />} />
            <Route path="exit" element={<Deregister />} />
            
            <Route path="headhunterjobregister" element={<HeadHunterJobRegister />} />
            <Route path="headhunterjoblist" element={<HeadHunterJobList />} />            
            <Route path="serviceinfo" element={<OnetoOneServiceInfoHeadHunter />} />            
            <Route path="serviceapply" element={<OnetoOneServiceApplyHeadHunter />} />
            
            <Route path="bookmarkcompanymain" element={<BookmarkedCompanyMain />} />
            <Route path="bookmarkpeople" element={<BookmarkedPeople />} />            
            <Route path="payment" element={<PaymentHistory />} />
            <Route path="message" element={<MessageMain />} />
          </Route>
          
          {/* <Route path="experiencemain" element={<ExperienceMain />} /> */}

          {/* ========================== test start ============================= */}
          {/* <Route path="bootstraptest" element={<BootstrapReactTest />} />
          <Route path="muigrid" element={<MUIDataGrid />} />   */}
          <Route path="mrttest" element={<MaterialReactTableTest />} />
          <Route path="httptest" element={<HttpRequestTest />} />
          <Route path="pwdTest" element={<PasswordValTest />} />
          {/* ========================== test end ============================= */}
          {/* TODO: wrong routing, redirect page ... */}
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
        <Footer></Footer>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
