import React from 'react';
import ReactDOM from 'react-dom/client';
// ref: https://blog.logrocket.com/using-bootstrap-with-react-tutorial-with-examples/
import "bootstrap/dist/css/bootstrap.min.css"; // TODO: do we really need this?
import "bootstrap/dist/js/bootstrap.bundle.min"; // TODO: do we really need this?
import "jquery/dist/jquery.slim"; // TODO: do we really need this?

// Importing Sass with Bootstrap CSS
import './Scss/App.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './components/Store/AuthContext';
import { QueryClient, QueryClientProvider } from "react-query";

const client = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <QueryClientProvider client={client}>
      <AuthContextProvider>
        <BrowserRouter>
          {<App />}
        </BrowserRouter>
      </AuthContextProvider>
    </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
